<template>
    <div class="refine-search-fields">
        <fmmp-autocomplete v-if="isDCP"
                :class="`driv-part-common brands ${searchFieldWidthClass}`"
                :config="autocompleteConfig"
                :id="brandItems.name"
                :name="brandItems.name"
                :items="dcpBrands"
                v-model="brandItems.model"
                :placeholder="getTranslation('Brands')"
                :loading="initialField.loading"
                @input="handleBrandChange"
            ></fmmp-autocomplete>
        <fmmp-autocomplete v-else
            :class="`driv-part-common ${initialField.name} ${searchFieldWidthClass}`"
            :config="autocompleteConfig"
            :id="initialField.name"
            :name="initialField.name"
            :items="initialField.items"
            v-model="initialField.model"
            :placeholder="getTranslation(initialField.placeholder)"
            :loading="initialField.loading"
            @input="initialField.onInput"
            :showLabel="showLabels"
        ></fmmp-autocomplete>
        <template v-if="initialField.model">
            <fmmp-autocomplete
                v-for="field in options"
                :key="field"
                :class="`driv-part-common ${field} ${searchFieldWidthClass}`"
                :config="autocompleteConfig"
                :id="field"
                :name="field"
                :items="fieldsConfig[field].items"
                :disabled="fieldsConfig[field].disabled"
                v-model="fieldsConfig[field].model"
                :placeholder="getTranslation(fieldsConfig[field].placeholder)"
                :loading="fieldsConfig[field].loading"
                @input="(value) => handleFieldInput(field, value)"
                :showLabel="showLabels"
            ></fmmp-autocomplete>
            <driv-refine-search-btn
                v-if="searchButton"
                :disabled="searchButton.isDisabled"
                :onClick="handleSearch"
            >
            </driv-refine-search-btn>
        </template>
        <div v-else class="search-txt desktop">{{ searchValuesString }}</div>
    </div>
</template>

<script>
import configs from "../../../../common/engine-search/EngineSearchConfig";
import { getModelFromFieldObject, resetField, getBrandsOptionsFromString } from "../../../../common/engine-search/helpers";
import { FIELDS } from "../../../../common/engine-search/constants";
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../../common/partFinderCorporate.constants";
import {
    createUrlToGoToSearchResults,
    getTranslation,
} from "../../../../common/partFinderCorporate.helpers";
import { getOptionsConfigBySelectedApplicationTypes } from "../../../../common/ymm-search/helpers";
import { checkForEnginePerformance } from "../helpers";

const { fieldsConfig, fieldsOptionsFromAEM, optionsConfig, searchButton, initialField, brandItems } = configs;

export default Vue.component("driv-refine-engine-search", {
    props: {
        searchParams: {
            vehicleGroupIds: {},
            engineMfr: {},
            performanceMfr: {},
            performanceBase: {},
            engineBase: {},
            engineUsage: {},
            vehicleType: {},
            vehicle: {},
            heavyMfr: {},
            heavyBase: {},
            brand_codes: {},
        },
        engineTypes: {
            type: String,
            default: "",
        },
        vehicleTypes: {
            type: String,
            default: "",
        },
        searchValuesString: {
            type: String,
            default: "",
        },
        subBrand: {
            type: String,
            default: "",
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
        heavyDutyBrands: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            optionsConfig,
            fieldsConfig,
            searchButton,
            initialField,
            isDCP: false,
            dcpBrands: [],
            brandcode:"",
            brandItems,
        };
    },
    computed: {
        config() {
            return getOptionsConfigBySelectedApplicationTypes({
                optionsConfig: this.optionsConfig,
                type: this.initialField.model,
            });
        },
        options() {
            return Object.keys(this.config.fields).filter((option) => this.fieldsConfig[option]);
        },
        resultsPagePath: () => {
            return window.location.pathname.replace(".html", "");
        },
        searchFieldWidthClass() {
            let widthClass = "";

            if (this.initialField.model?.value) {
                const ymmFieldsQty = 1 + this.options.length;
                widthClass = `width-${ymmFieldsQty}`;
            }
            return widthClass;
        },
        isEnginePerformance() {
            return checkForEnginePerformance(this.initialField);
        },
        isEngineHeavyDuty() {
            const searchTypeId = getModelFromFieldObject(this.initialField)?.value;
            return searchTypeId === Vue.PartFinder.LOOK_UP_IDS.HEAVY_DUTY;
        },
        currentSearchType() {
            if (this.isEnginePerformance) {
                return PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;
            } else if (this.isEngineHeavyDuty) {
                return PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY;
            }
            return PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE;
        },
    },
    watch: {
        engineTypes: function (newEngineTypes) {
            resetField(this.initialField);
            this.initialField.getItems(newEngineTypes);
        },
        "initialField.items": function (newItems) {
            if (newItems.length === 1) {
                const resolveAllDependentFields =
                    this.searchParams.vehicleGroupIds?.value === newItems[0].value;
                this.initialField.model = newItems[0];
                this.initialField.onInput(newItems[0], resolveAllDependentFields);
                resolveAllDependentFields && this.setEngineOptionsValue();
            } else if (this.searchParams.vehicleGroupIds?.value) {
                const option = this.initialField.items.find(
                    ({ value }) => value === this.searchParams.vehicleGroupIds.value,
                );

                if (option) {
                    this.initialField.model = option;
                    this.initialField.onInput(option, true);
                    this.setEngineOptionsValue();
                }
            }
        },
        "brandItems.items": function(newitems) {
            if (this.searchParams.brands?.value) {
                try {
                    const option = this.brandItems.items.find(
                        ({ value }) => value === this.searchParams.brands.value,
                    );

                    if (option) {
                            this.brandItems.model = option;
                    }
                    this.brandItems.getItems(this.engineTypes);
                    this.$set(this.fieldsConfig[FIELDS.HEAVY_DUTY_BRANDS], 'model', option);
                } catch(e) {
                    console.log(e);
                }
            }
        } 
    },
    methods: {
        getTranslation,
        handleFieldInput(field, value) {
            this.config.fields[field].onInput(value);
        },
        handleSearch() {
            if (this.resultsPagePath) {
                const vehicleGroupIds = getModelFromFieldObject(this.initialField);
                const engineMfr = getModelFromFieldObject(this.fieldsConfig[FIELDS.MANUFACTURER]);
                const engineBase = getModelFromFieldObject(this.fieldsConfig[FIELDS.BASE]);
                const engineUsage = getModelFromFieldObject(this.fieldsConfig[FIELDS.USAGE]);
                const vehicle = getModelFromFieldObject(this.fieldsConfig[FIELDS.VEHICLE]);
                const performanceMfr = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.PERFORMANCE_MFRS],
                );
                const performanceBase = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.PERFORMANCE_BASE],
                );
                const heavyMfr = getModelFromFieldObject(this.fieldsConfig[FIELDS.HEAVY_DUTY_MFRS]);
                const heavyBase = getModelFromFieldObject(
                    this.fieldsConfig[FIELDS.HEAVY_DUTY_BASE],
                );

                let queryObj = {};
                if (this.isDCP) {
                    const brands =  getModelFromFieldObject(
                        this.fieldsConfig[FIELDS.HEAVY_DUTY_BRANDS],
                    );

                    queryObj = {
                        searchType: this.currentSearchType,
                        vehicleGroupIds,
                        ...(engineMfr?.value ? { engineMfr } : {}),
                        ...(engineBase?.value ? { engineBase } : {}),
                        ...(engineUsage?.value ? { engineUsage } : {}),
                        ...(vehicle?.value ? { vehicle } : {}),
                        ...(performanceMfr?.value ? { performanceMfr } : {}),
                        ...(performanceBase?.value ? { performanceBase } : {}),
                        ...(heavyMfr?.value ? { heavyMfr } : {}),
                        ...(heavyBase?.value ? { heavyBase } : {}),
                        ...(brands?.value ? { brands } : {}),
                        brand_codes: brands?.value,
                        own_brand: window.brand,
                    };
                } else {
                    queryObj = {
                        searchType: this.currentSearchType,
                        vehicleGroupIds,
                        ...(engineMfr?.value ? { engineMfr } : {}),
                        ...(engineBase?.value ? { engineBase } : {}),
                        ...(engineUsage?.value ? { engineUsage } : {}),
                        ...(vehicle?.value ? { vehicle } : {}),
                        ...(performanceMfr?.value ? { performanceMfr } : {}),
                        ...(performanceBase?.value ? { performanceBase } : {}),
                        ...(heavyMfr?.value ? { heavyMfr } : {}),
                        ...(heavyBase?.value ? { heavyBase } : {}),
                    };
                }

                const url = createUrlToGoToSearchResults({
                    queryObj,
                    path: this.resultsPagePath,
                });

                const eventParams = [
                    vehicleGroupIds.value,
                    Object.values(queryObj)
                        .map((item) => item.label)
                        .join("~"),
                ].join("|");

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-filter",
                    eventParams,
                    null,
                    {
                        callback: function () {
                            window.location.assign(url);
                        },
                    },
                );
            }

            return false;
        },
        setEngineOptionsValue() {
            const searchData = {
                [FIELDS.MANUFACTURER]: this.searchParams.engineMfr,
                [FIELDS.PERFORMANCE_MFRS]: this.searchParams.performanceMfr,
                [FIELDS.PERFORMANCE_BASE]: this.searchParams.performanceBase,
                [FIELDS.HEAVY_DUTY_MFRS]: this.searchParams.heavyMfr,
                [FIELDS.HEAVY_DUTY_BASE]: this.searchParams.heavyBase,
                [FIELDS.BASE]: this.searchParams.engineBase,
                [FIELDS.USAGE]: this.searchParams.engineUsage,
                [FIELDS.VEHICLE]: this.searchParams.vehicle,
                [FIELDS.HEAVY_DUTY_BRANDS]: this.searchParams.brands,
            };

            this.options.forEach((option) => {
                if (!searchData[option]?.value) return;

                this.fieldsConfig[option].model = searchData[option];
                this.fieldsConfig[option].disabled = false;
            });
        },
        handleBrandChange(brand) {
            this.$set(this.fieldsConfig[FIELDS.HEAVY_DUTY_BRANDS], 'model', brand);
            this.brandItems.getSelectedBrand(brand);
            this.brandItems.getItems(this.engineTypes);         
        }
    },
    created() {
        this.isDCP = window.brand === 'commercial-parts' ? true : false;
        if (this.engineTypes && !this.isDCP) {
            this.initialField.getItems(this.engineTypes);
        } 

        if (this.isDCP && this.searchParams.searchType === 'heavy') {
            this.dcpBrands = getBrandsOptionsFromString(this.heavyDutyBrands);
            this.brandItems.items = this.dcpBrands;
            this.brandItems.model = this.searchParams.brands;
        }
    },
    beforeDestroy() {
        resetField(this.initialField);
    },
});
</script>
